<template>
  <div id="login-page">
    <FirstTemplateHome>
      <img alt="logo" src="@/assets/logo.png" class="img-logo" />
      <el-divider content-position="center">เข้าสู่ระบบ</el-divider>
      <el-form
        @submit.native.prevent="submitLogin"
        label-position="top"
        :model="form"
        ref="ruleFormLogin"
        :rules="rules"
      >
        <el-form-item label="อีเมล" prop="email">
          <el-input v-model="form.email" clearable placeholder="กรุณากรอกอีเมล">
          </el-input>
        </el-form-item>
        <el-form-item label="รหัสผ่าน" prop="password">
          <el-input
            v-model="form.password"
            show-password
            placeholder="กรุณากรอกรหัสผ่าน"
          >
          </el-input>
        </el-form-item>
        <p class="text-right mg-y-3">
          <span
            class="cr-pointer color-pending font-16"
            @click="$router.push(`/forgot-password`)"
            >ลืมรหัสผ่าน?</span
          >
        </p>
        <el-button
          type="warning"
          native-type="submit"
          round
          class="mg-b-4 w-100"
          >เข้าสู่ระบบ</el-button
        >
        <div class="text-center">
          <span class="mg-r-3">ยังไม่มีบัญชี?</span>
          <span
            class="cr-pointer color-pending text-decoration-underline"
            @click="$router.push(`/register`)"
            >สมัครสมาชิก</span
          >
        </div>
      </el-form>
    </FirstTemplateHome>
  </div>
</template>
<script>
import "@/mixin/FirstTemplateHome";

import { HTTP } from "@/service/axios";

export default {
  mounted() {
    window.localStorage.removeItem("UIC");
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur",
          },
          {
            type: "email",
            message: "กรุณากรอกอีเมลให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "กรุณากรอกรหัสผ่าน",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async submitLogin() {
      this.$refs["ruleFormLogin"].validate((valid) => {
        if (valid) {
          delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];
          HTTP.post(`login`, this.form)
            .then(async (res) => {
              if (res.data.success) {
                this.$store.commit("SET_USER", res.data.obj);
                this.$store.commit("SET_TOKEN", res.data.obj.token);
                await this.connectCSRF(res.data.obj.token, res.data.obj.role);
              } else {
                if (res.data.data == "Not to access") {
                  this.$notify({
                    title: "คุณไม่มีสิทธิ์เข้าถึง!",
                    type: "error",
                    customClass: "error",
                  });
                } else {
                  this.$notify({
                    title: "อีเมลหรือรหัสผ่านไม่ถูกต้อง!",
                    type: "error",
                    customClass: "error",
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 422) {
                this.$notify({
                  title: "อีเมลหรือรหัสผ่านไม่ถูกต้อง!",
                  type: "error",
                  customClass: "error",
                });
              }
            });
        }
      });
    },
    connectCSRF(token, role) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
      HTTP.get(`user/getcsrftoken`).then((res) => {
        if (res.data.success) {
          this.$csrf.set(res.data.csrfToken);
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = res.data.csrfToken;
          this.$store.commit("SET_CSRF", res.data.csrfToken);
          let csrfTokenMeta = document.querySelector(
            'meta[name="csrf-token"]'
          );
          csrfTokenMeta.setAttribute("content",res.data.csrfToken);

          // console.log('csrfTokenMeta',csrfTokenMeta)
          if (role == 80 || role == 60) {
            this.$router.push(`/master-data/admin`);
          } else if (role == 50) {
            this.$router.push(`/calendar`);
          }
        }
      });
    },
  },
};
</script>
